import fetch from 'cross-fetch';
import Vue from 'vue';

const FormData = require('form-data');

const apiPrefix = process.env.VUE_APP_API_URL;

export const common = function () {
    return call('common');
}

const slides = function () {
    return call('slides');
}

const posts = function () {
    return call('posts');
}

const post = function (slug) {
    return call('posts/'+ slug);
}

const homepage = function () {
    return call('homepage');
}

const appointment = function (data) {
    const formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key])
    }

    return call('appointment', {
        method: 'POST',
        body: formData,
    });
}

function call(uri, init) {
    init = init || {};
    return fetch(apiPrefix + uri, init)
        .then((response) => response.json())
        .catch(function(err) {
            Vue.prototype.$rollbar.error(err);

            throw err;
        });
}

function notifyMissedTranslation(locale, phrase) {
    const formData = new FormData();
    formData.append('locale', locale);
    formData.append('phrase', phrase);

    return call('missed-translation', {
        method: 'POST',
        body: formData,
    });
}

export default  {
    common,
    slides,
    posts,
    post,
    homepage,
    appointment,
    notifyMissedTranslation,
};
