<template>
    <div class="revolution-slider-container">
        <div class="revolution-slider" data-version="5.4.8" :class="shouldShowPreloader ? 'slider-preloader' : ''">
            <ul style="display: none;">

                <!-- SLIDE 1 -->
                <li v-for="slide of slides" data-transition="fade" data-masterspeed="500" data-slotamount="1" data-delay="6000">
                    <!-- MAIN IMAGE -->
                    <img :src="slide.imageUrl()" :alt="slide.title($store.getters.locale)" data-bgfit="cover">
                    <!-- LAYERS -->
                    <!-- LAYER 01 -->
                    <div class="tp-caption"
                         data-frames='[{"delay":500,"speed":1200,"from":"y:-40;o:0;","ease":"easeInOutExpo"},{"delay":"wait","speed":500,"to":"o:0;","ease":"easeInOutExpo"}]'
                         data-x="center"
                         data-y="97"
                    >
                        <!--data-start="500"
                        data-transform_in="x:0;y:-40;o:0;s:1200;e:easeInOutExpo;"
                        data-transform_out="x:0;y:0;o:0;s:500;e:easeInOutExpo;"-->

                        <div class="hexagon"><div :class="slide.icon()"></div></div>
                    </div>
                    <!-- LAYER 02 -->
                    <div class="tp-caption"
                         data-frames='[{"delay":900,"speed":1200,"from":"y:40;o:0;","ease":"easeInOutExpo"},{"delay":"wait","speed":500,"to":"o:0;","ease":"easeInOutExpo"}]'
                         data-x="center"
                         data-y="262"
                    >
                        <!--data-start="900"
                        data-transform_in="x:0;y:40;o:0;s:1200;e:easeInOutExpo;"
                        data-transform_out="x:0;y:0;o:0;s:500;e:easeInOutExpo;"-->

                        <h2>
                            <router-link exact :to="{ name: 'home', hash: '#book-an-appointment', params: { locale: $store.getters.locale } }" :title="slide.title($store.getters.locale)">
                                {{ slide.title($store.getters.locale) }}
                            </router-link>
                        </h2>
                    </div>
                    <!-- LAYER 03 -->
                    <div class="tp-caption"
                         data-frames='[{"delay":1100,"speed":1200,"from":"y:40;o:0;","ease":"easeInOutExpo"},{"delay":"wait","speed":500,"to":"o:0;","ease":"easeInOutExpo"}]'
                         data-x="center"
                         data-y="343"
                    >
                        <!--data-start="1100"
                        data-transform_in="x:0;y:40;o:0;s:1200;e:easeInOutExpo;"
                        data-transform_out="x:0;y:0;o:0;s:500;e:easeInOutExpo;"-->

                        <p class="description">{{ slide.description($store.getters.locale) }}</p>
                    </div>
                    <!-- LAYER 04 -->
                    <div v-if="slide.showBookAppointmentButton()"
                         class="tp-caption"
                         data-frames='[{"delay":1300,"speed":1200,"from":"y:40;o:0;","ease":"easeInOutExpo"},{"delay":"wait","speed":500,"to":"o:0;","ease":"easeInOutExpo"}]'
                         data-x="center"
                         data-y="441"
                    >
                        <!--data-start="1300"
                        data-transform_in="x:0;y:40;o:0;s:1200;e:easeInOutExpo;"
                        data-transform_out="x:0;y:0;o:0;s:500;e:easeInOutExpo;"-->

                        <div class="align-center">
                            <router-link exact :to="{ name: 'home', hash: '#book-an-appointment', params: { locale: $store.getters.locale } }" class="more simple">
                                <span>{{ 'Забронювати' | translate }}</span>
                            </router-link>
                        </div>
                    </div>
                    <!-- / -->
                </li>

            </ul>
        </div>
    </div>
</template>


<style>
    .revolution-slider-container .slotholder:after
    {
        content: '';
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        background: rgba(30,30,30,0.5);
    }
    body .tp-loader.spinner0 {
        display: none;
        visibility: hidden;
    }
</style>

<style scoped>
    .slider-preloader {
        background-image: url(../assets/preloader.gif);
        background-repeat: no-repeat;
        background-position: center;
        background-color: #fff;
    }
    .more {
        text-transform: uppercase;
    }
    div.revolution-slider {
        background-color: #fff;
    }
</style>


<script>
    export default {
        data() {
            return {
                shouldShowPreloader: true,
                scriptsDownloaded: false,
            }
        },

        computed: {
            slides() {
                return this.$store.getters.slides;
            },
        },

        mounted() {
            if (this.isBrowser()) {
                Promise.all([
                    this.$loadScript("/rs-plugin/js/jquery.themepunch.tools.min.js"),
                    this.$loadScript("/rs-plugin/js/jquery.themepunch.revolution.min.js"),
                ]).then((data) => {
                    this.scriptsDownloaded = true;
                });
            }
        },

        methods: {
            isBrowser () {
                return typeof window !== 'undefined';
            },

            initSlider () {
                this.shouldShowPreloader = false;
                this.$nextTick(function () {
                    $('.revolution-slider').show().revolution({
                        dottedOverlay:"none",
                        delay:10000,
                        gridwidth:1170,
                        gridheight:600,
                        sliderLayout:"auto",
                        navigation: {
                            keyboardNavigation:"on",
                            onHoverStop:"on",
                            touch:{
                                touchenabled:"on",
                                swipe_treshold : 75,
                                swipe_min_touches : 1,
                                drag_block_vertical:false,
                                swipe_direction:"horizontal"
                            },
                            arrows: {
                                style:"preview1",
                                enable:true,
                                hide_onmobile:true,
                                hide_onleave:true,
                                hide_delay:200,
                                hide_delay_mobile:1500,
                                hide_under:0,
                                hide_over:9999,
                                tmp:'',
                                left : {
                                    h_align:"left",
                                    v_align:"center",
                                    h_offset:0,
                                    v_offset:0,
                                },
                                right : {
                                    h_align:"right",
                                    v_align:"center",
                                    h_offset:0,
                                    v_offset:0
                                }
                            },
                            bullets: {
                                style:"preview1",
                                enable:true,
                                hide_onmobile:true,
                                hide_onleave:true,
                                hide_delay:200,
                                hide_delay_mobile:1500,
                                hide_under:0,
                                hide_over:9999,
                                direction:"horizontal",
                                h_align:"center",
                                v_align:"bottom",
                                space:10,
                                h_offset:0,
                                v_offset:20,
                                tmp:''
                            },
                            parallax:{
                                type:"off",
                                bgparallax:"off",
                                disable_onmobile:"on"
                            }
                        },
                        shadow:0,
                        spinner:"spinner0",
                        stopAfterLoops:-1,
                        stopAtSlide:-1,
                        disableProgressBar: "on"
                    });
                });
            }
        },

        watch: {
            slides () {
                if (!this.scriptsDownloaded) {
                    return;
                }
                this.initSlider();
            },

            scriptsDownloaded () {
                if (!this.slides.length) {
                    return;
                }
                this.initSlider();
            },
        }
    }
</script>
