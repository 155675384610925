<template>
    <div id="prices" class="row gray full-width padding-top-54 padding-bottom-61 align-center">
        <h3>
            <span class="button-label">{{ price }}</span>
            <router-link exact :to="{ name: 'home', hash: '#book-an-appointment', params: { locale: $store.getters.locale } }" class="more">
                <span>{{ 'Забронювати' | translate }}</span>
            </router-link>
        </h3>
    </div>
</template>

<script>
    export default {
        name: "Price",
        computed: {
            price() {
                return this.$store.getters.price(this.$store.getters.locale);
            }
        }
    }
</script>

<style scoped>
    a.more > span {
        text-transform: uppercase;
    }
    @media screen and (max-width: 479px) {
        span.button-label {
            display: block;
            margin-bottom: 45px;
        }
    }
</style>