<template>
    <div class="row dark-gray footer-row full-width padding-bottom-50">
        <div class="map">
            <iframe frameborder="0"
                    width="100%"
                    height="100%"
                    style="border:0;"
                    allowfullscreen=""
                    defer
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d318.0842999520173!2d30.91250560845486!3d50.37262554329822!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4e94f5e7e5c6d%3A0x7d616467207e1771!2z0JDQstGC0L7RgdGC0L7Rj9C90LrQsCBGbHlQYXJraW5n!5e0!3m2!1sru!2sua!4v1592253403768!5m2!1sru!2sua&z=14&zomm=14"></iframe>
        </div>

        <div id="contact-us" class="row padding-top-30">
            <div class="column column-1-4">
                <ul class="contact-details-list">
                    <li class="sl-small-location-map">
                        <p>
                            {{ 'Ми працюємо 24/7' | translate }}
                            <br>
                            <span class="wh">{{ address }}</span>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="column column-1-4">
                <ul class="contact-details-list">
                    <li class="sl-small-phone-circle">
                        <p>
                            {{ 'Бронювання по телефону | Viber' | translate }}
                        <ol>
                            <li v-for="phone of phones">
                                <a :href="`tel:${sanitizePhone(phone)}`"
                                   onclick="return gtag_report_conversion(this.href);"
                                   class="contact-telephone">
                                    {{ phone }}
                                </a>
                            </li>
                        </ol>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="column column-1-4">
                <ul class="contact-details-list">
                    <li class="sl-small-phone-circle">
                        <p>
                            {{ 'Служба таксі' | translate }}
                        <ol>
                            <li v-for="phone of cabPhones">
                                <a :href="`tel:${sanitizePhone(phone)}`"
                                   class="contact-telephone">
                                    {{ phone }}
                                </a>
                            </li>
                        </ol>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="column column-1-4">
                <ul class="contact-details-list">
                    <li class="sl-small-email">
                        <p>
                            {{ 'E-mail' | translate }}
                            <br>
                            <a :href="`mailto:${email}`"  class="contact-telephone">{{ email }}</a>
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Contacts",
        computed: {
            email() {
                return this.$store.getters.email;
            },
            address() {
                return this.$store.getters.address[this.$store.getters.locale];
            },
            phones() {
                return this.$store.getters.phones;
            },
            cabPhones() {
                return this.$store.getters.cab_phones;
            },
        },
        methods: {
            adwordsTracking(url) {
                if (typeof gtag_report_conversion === 'function') {
                    gtag_report_conversion(url);
                }
            },
        },
    }
</script>

<style scoped>
    span.wh,
    .contact-details-list li p a {
        color: #fff;
    }
    .contact-details-list li p {
        color: #ccc;
    }
    div.row.dark-gray {
        background: #0f2a4f;
    }
    li.sl-small-email {
        line-break: anywhere;
    }

    .map {
        background-color: #fff;
        min-height: 450px;
        height: 450px;
        width: 100%;
    }


    @media screen and (max-width: 1189px) {
        .column-1-4 {
            width: 100%;
            margin-left: 0;
        }
    }
</style>

