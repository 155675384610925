<template>
    <div class="header-top-bar-container clearfix">
        <div class="header-top-bar">
            <ul class="contact-details clearfix">
                <li v-for="phone in phones" class="template-phone">
                    <a :href="`tel:${sanitizePhone(phone)}`"
                       onclick="return gtag_report_conversion(this.href);">
                        {{ phone }}
                    </a>
                </li>
                <li class="template-mail">
                    <a :href="`mailto:${email}`">{{ email }}</a>
                </li>
            </ul>
            <ul class="social-icons">
                <li v-for="locale of $store.getters.locales">
                    <a href=""
                       v-on:click="changeLocale($event, locale)"
                       :class="{ 'locale-selected': locale === $store.getters.locale }">
                        {{ locale | uppercase }}
                    </a>
                </li>
                <li v-if="facebook_url">
                    <div class="empty-top"></div>
                </li>
                <li v-if="facebook_url">
                    <a target="_blank" :href="facebook_url" class="social-facebook"></a>
                </li>
            </ul>
        </div>
        <a href="" class="header-toggle template-arrow-up"></a>
    </div>
</template>


<script>
    export default {
        computed: {
            email() {
                return this.$store.getters.email;
            },
            facebook_url() {
                return this.$store.getters.facebook_url;
            },
            phones() {
                return this.$store.getters.phones;
            },
        },
        methods: {
            changeLocale(event, locale) {
                event.preventDefault();

                this.$store.commit('locale', locale);

                let segments = window.location.pathname.split('/');
                segments[1] = this.$store.getters.locale;

                history.pushState(null, '', segments.join('/'));
            },

            adwordsTracking(url) {
                if (typeof gtag_report_conversion === 'function') {
                    gtag_report_conversion(url);
                }
            },
        },
    }
</script>

<style scoped>
    div.empty-top {
        padding: 25px;
    }
    .locale-selected {
        border-bottom: 2px solid #1e69b8;
        -webkit-box-shadow: inset 0px -5px 0px -4px #1e69b8;
        -moz-box-shadow: inset 0px -5px 0px -4px #1e69b8;
        box-shadow: inset 0px -5px 0px -4px #1e69b8;
    }
</style>
