<template>
    <div>
        <not-found v-if="$store.getters.ready && !post.id()"></not-found>

        <div v-else class="theme-page padding-bottom-66">

            <div class="row gray full-width page-header vertical-align-table">
                <div class="row full-width padding-top-bottom-50 vertical-align-cell">
                    <div class="row">
                        <div class="page-header-left">
                            <h1>{{ 'Новини' | translate }}</h1>
                        </div>
                        <div class="page-header-right">
                            <div class="bread-crumb-container">
                                <ul class="bread-crumb">
                                    <li>
                                        <router-link exact :to="{ name: 'home', params: { locale: $store.getters.locale } }">{{ 'Головна' | translate }}</router-link>
                                    </li>
                                    <li class="separator">
                                        &#47;
                                    </li>
                                    <li>
                                        <router-link exact :to="{ name: 'news', params: { locale: $store.getters.locale } }">{{ 'Новини' | translate }}</router-link>
                                    </li>
                                    <li class="separator">
                                        &#47;
                                    </li>
                                    <li>
                                        {{ post.title($store.getters.locale) }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="clearfix">
                <div class="row margin-top-70">
                    <div class="column column-3-4">
                        <div class="blog clearfix">
                            <div class="post single">
                                <!--                            <img src="" alt="">-->
                                <div class="post-content-details-container clearfix">
                                    <ul class="post-content-details">
                                        <li>
                                            {{ post.monthName($store.getters.locale) }} {{ post.day() }}, {{ post.year() }}
                                        </li>
                                    </ul>
                                </div>
                                <h3 class="box-header">
                                    {{ post.title($store.getters.locale) }}
                                </h3>

                                <div class="post-content">
                                    <vue-markdown :source="post.content($store.getters.locale)"></vue-markdown>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="column column-1-4 re-smart-column">
                        <div class="re-smart-column-wrapper">
                            <book-appointment></book-appointment>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BookAppointment from "../components/News/BookAppointment";
    import VueMarkdown from 'vue-markdown';
    import PageNotFound from "../components/PageNotFound";


    export default {
        name: "NewsPost",

        components: {
            'book-appointment': BookAppointment,
            'vue-markdown': VueMarkdown,
            'not-found': PageNotFound,
        },

        computed: {
            post() {
                return this.$store.getters.post(this.$route.params.slug);
            },
        },

        metaInfo() {
            return {
                title: this.post.seo_title(this.$store.getters.locale),
                meta: [
                    {
                        name: 'description',
                        content: this.post.seo_description(this.$store.getters.locale),
                    },
                    {
                        name: 'keywords',
                        content: this.post.seo_keywords(this.$store.getters.locale),
                    },
                ],
            }
        },
    }
</script>


<style>
    div.post-content img {
        width: 100%;
    }
</style>

<style scoped>
    @media screen and (max-width: 1189px) {
        div.page-header-right {
            display: none;
        }
    }

    h1,
    .page-header-right {
        text-transform: uppercase;
    }
    .page-header-right {
        width: 850px;
    }
    .page-header-left {
        width: 300px;
    }

    div.post.single {
        padding-bottom: 0;
        border-bottom: none;
    }
</style>

