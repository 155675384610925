<template>
    <div>
        <slider></slider>
        <about-us></about-us>
        <advantages></advantages>
        <services></services>
        <price></price>
        <testimonials></testimonials>
        <add-testimonial></add-testimonial>
        <appointment-form></appointment-form>
        <contacts></contacts>
    </div>
</template>

<script>
    import Slider from "../components/Slider";
    import Contacts from "../components/Contacts";
    import Services from "../components/Services";
    import AboutUs from "../components/AboutUs";
    import Advantages from "../components/Advantages";
    import Testimonials from "../components/Testimonials";
    import AppointmentForm from "../components/AppointmentForm";
    import AddTestimonial from "../components/AddTestimonial";
    import Price from "../components/Price";

    export default {
        components: {
            'slider': Slider,
            'contacts': Contacts,
            'services': Services,
            'advantages': Advantages,
            'about-us': AboutUs,
            'testimonials': Testimonials,
            'add-testimonial': AddTestimonial,
            'appointment-form': AppointmentForm,
            'price': Price,
        },
        metaInfo() {
            let meta =  {
                title: this.$store.getters.homepage_seo.title[this.$store.getters.locale],
                meta: [
                    {
                        name: 'description',
                        content: this.$store.getters.homepage_seo.title[this.$store.getters.locale],
                    },
                    {
                        name: 'keywords',
                        content: this.$store.getters.homepage_seo.title[this.$store.getters.locale],
                    },
                ],
            };

            if (this.$store.getters.locale == this.$store.getters.defaultLocale) {
                meta.link = [
                    {rel: 'canonical', href: 'https://flyparking.com.ua/'}
                ];
            }

            return meta;
        },
    }
</script>
