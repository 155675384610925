<template>
    <a href="#top" class="scroll-top animated-element template-arrow-up" title="Scroll to top"></a>
</template>

<script>
    export default {
        name: "ScrollToTop",
        mounted() {
            $("a[href='#top']").on("click", function() {
                $("html, body").animate({scrollTop: 0}, "slow");
                return false;
            });
        }
    }
</script>

<style scoped>

</style>