const Translator = (function () {
    let instance;
    let translations = {};

    const get = function (phrase, locale) {
        if (translations.hasOwnProperty(phrase) && translations[phrase].hasOwnProperty(locale)) {
            return translations[phrase][locale];
        }

        if (!translations.hasOwnProperty(phrase)) {
            translations[phrase] = {};
        }
        translations[phrase][locale] = phrase;

        notifyMissedTranslation(locale, phrase);

        return phrase;
    }

    const notifyMissedTranslation = function (locale, phrase) {
        if (!Object.keys(translations).length) {
            return;
        }

        // const formData = new FormData();
        // formData.append('locale', locale);
        // formData.append('phrase', phrase);
        // fetch('http://localhost:81', {
        //     method: 'POST',
        //     body: formData
        // })
    }

    const setTranslations = function (newTranslations) {
        translations = newTranslations;
    }

    const createInstance = function () {
        return {
            get: get,
            setTranslations: setTranslations,
        }
    }

    return {
        getInstance: function () {
            return instance || (instance = createInstance());
        }
    }
})();

export default Translator;