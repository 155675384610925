<template>
    <div class="theme-page padding-bottom-66">
        <div class="clearfix page-404 top-border">
            <div class="row margin-top-70">
                <div class="column column-1-1">
                    <h2>OOPS.</h2>
                    <h1>404</h1>
                    <p class="description align-center">{{ 'Прикро, але сталась помилка! Схоже такої сторінки немає.' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PageNotFound",

        metaInfo() {
            return {
                title: this.$store.getters.homepage_seo.title[this.$store.getters.locale],
                meta: [
                    {
                        name: 'description',
                        content: this.$store.getters.homepage_seo.title[this.$store.getters.locale],
                    },
                    {
                        name: 'keywords',
                        content: this.$store.getters.homepage_seo.title[this.$store.getters.locale],
                    },
                ],
            }
        },

        mounted: function(){
            //console.log('mounted', this._inactive)
        },
        destroyed: function () {
            //console.log('destroyed', this._inactive)
        }
    }
</script>
