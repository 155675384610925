<template>
    <li>
        <ul class="post-details">
            <li class="date template-calendar">
                {{ post.day() }}
                <h2>{{ post.monthShortName($store.getters.locale) }}</h2>
                {{ post.year() }}
            </li>
        </ul>
        <div class="post-content">
            <h3 class="box-header">
                <router-link
                        :to="{
                            name: 'news-post',
                            params: {
                                slug: post.slug(),
                                locale: $store.getters.locale,
                            },
                        }">
                    {{ post.title($store.getters.locale) }}
                </router-link>
            </h3>

            <p>{{ post.description($store.getters.locale) }}</p>
            <div class="row padding-top-30 padding-bottom-61">
                <router-link
                        :to="{
                            name: 'news-post',
                            params: {
                                slug: post.slug(),
                                locale: $store.getters.locale,
                            },
                        }"
                        class="more">
                    {{ 'детальніше' | translate }}
                </router-link>
            </div>
        </div>
    </li>
</template>

<script>
    import Post from "../../models/Post";

    export default {
        name: "PostCard",
        props: {
            post: {
                type: Post,
            }
        },
    }
</script>

<style scoped>
    a.more {
        padding: 12px 24px 14px;
    }
</style>