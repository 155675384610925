import BaseModel from "./BaseModel";

export default class Post extends BaseModel {
    id() {
        return this.get('id');
    }

    title(locale) {
        return this.getTranslation('title', locale);
    }

    content(locale) {
        return this.getTranslation('content', locale);
    }

    slug() {
        return this.get('slug');
    }

    description(locale) {
        return this.getTranslation('description', locale);
    }

    year() {
        return new Date(this.get('created_at')).getFullYear();
    }

    seo_title(locale) {
        return this.getTranslation('seo_title', locale);
    }

    seo_description(locale) {
        return this.getTranslation('seo_description', locale);
    }

    seo_keywords(locale) {
        return this.getTranslation('seo_keywords', locale);
    }

    monthShortName(locale) {
        return {
            uk: {
                1: 'Січ',
                2: 'Лют',
                3: 'Бер',
                4: 'Квіт',
                5: 'Трав',
                6: 'Черв',
                7: 'Лип',
                8: 'Серп',
                9: 'Вер',
                10: 'Жовт',
                11: 'Лист',
                12: 'Груд',
            },
            ru: {
                1: 'Янв',
                2: 'Фев',
                3: 'Мар',
                4: 'Апр',
                5: 'Май',
                6: 'Июн',
                7: 'Июл',
                8: 'Авг',
                9: 'Сен',
                10: 'Окт',
                11: 'Ноя',
                12: 'Дек',
            },
        }[locale][new Date(this.get('created_at')).getMonth()];
    }

    monthName(locale) {
        return {
            uk: {
                1: 'Січень',
                2: 'Лютий',
                3: 'Березень',
                4: 'Квітень',
                5: 'Травень',
                6: 'Червень',
                7: 'Липень',
                8: 'Серпень',
                9: 'Вересень',
                10: 'Жовтень',
                11: 'Листопад',
                12: 'Грудень',
            },
            ru: {
                1: 'Январь',
                2: 'Февраль',
                3: 'Март',
                4: 'Апрель',
                5: 'Май',
                6: 'Июнь',
                7: 'Июль',
                8: 'Август',
                9: 'Сентябрь',
                10: 'Октябрь',
                11: 'Ноябрь',
                12: 'Декабрь',
            },
        }[locale][new Date(this.get('created_at')).getMonth()];
    }

    day() {
        return new Date(this.get('created_at')).getDay();
    }
}
