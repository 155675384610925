var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('ul',{staticClass:"post-details"},[_c('li',{staticClass:"date template-calendar"},[_vm._v(" "+_vm._s(_vm.post.day())+" "),_c('h2',[_vm._v(_vm._s(_vm.post.monthShortName(_vm.$store.getters.locale)))]),_vm._v(" "+_vm._s(_vm.post.year())+" ")])]),_c('div',{staticClass:"post-content"},[_c('h3',{staticClass:"box-header"},[_c('router-link',{attrs:{"to":{
                        name: 'news-post',
                        params: {
                            slug: _vm.post.slug(),
                            locale: _vm.$store.getters.locale,
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.post.title(_vm.$store.getters.locale))+" ")])],1),_c('p',[_vm._v(_vm._s(_vm.post.description(_vm.$store.getters.locale)))]),_c('div',{staticClass:"row padding-top-30 padding-bottom-61"},[_c('router-link',{staticClass:"more",attrs:{"to":{
                        name: 'news-post',
                        params: {
                            slug: _vm.post.slug(),
                            locale: _vm.$store.getters.locale,
                        },
                    }}},[_vm._v(" "+_vm._s(_vm._f("translate")('детальніше'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }