<template>
    <div class="row gray full-width padding-top-30 padding-bottom-30 align-center">
        <a href="https://g.page/flyparkingboryspil/review?rc" target="_blank" class="more">
            <span>{{ 'Залишити відгук' | translate }}</span>
        </a>
    </div>
</template>

<script>
    export default {
        name: "AddTestimonial",
    }
</script>

<style scoped>
    a.more {
        margin: 0;
    }
    a.more > span {
        text-transform: uppercase;
    }
    @media screen and (max-width: 479px) {
        span.button-label {
            display: block;
            margin-bottom: 45px;
        }
    }
</style>