<template>
    <div class="header-container sticky">
            <!--<div class="header-container sticky">-->
            <div class="vertical-align-table column-1-1">
                <div class="header clearfix">
                    <div class="logo vertical-align-cell">
                        <h1>
                            <router-link exact :to="{ name: 'home', params: { locale: $store.getters.locale } }">
                                <img src="../assets/logo.svg" alt="FlyParking" class="logo-img">
                            </router-link>
                        </h1>
                    </div>
                    <a href="#" class="mobile-menu-switch vertical-align-cell">
                        <span class="line"></span>
                        <span class="line"></span>
                        <span class="line"></span>
                    </a>
                    <div class="menu-container clearfix vertical-align-cell">
                        <nav>
                            <ul class="sf-menu">
                                <li class="">
                                    <router-link exact :to="{ name: 'home', params: { locale: $store.getters.locale } }">{{ 'Головна' | translate }}</router-link>
                                </li>
                                <li class="">
                                    <router-link exact :to="{ name: 'home', hash: '#about-us', params: { locale: $store.getters.locale } }">{{ 'Про нас' | translate }}</router-link>
                                </li>
                                <li class="">
                                    <router-link exact :to="{ name: 'home', hash: '#advantages', params: { locale: $store.getters.locale } }">{{ 'Переваги' | translate }}</router-link>
                                </li>
                                <li class="">
                                    <router-link exact :to="{ name: 'home', hash: '#services', params: { locale: $store.getters.locale } }">{{ 'Послуги' | translate }}</router-link>
                                </li>
                                <li class="">
                                    <router-link exact :to="{ name: 'home', hash: '#prices', params: { locale: $store.getters.locale } }">{{ 'Ціни' | translate }}</router-link>
                                </li>
                                <li class="">
                                    <router-link exact :to="{ name: 'news', params: { locale: $store.getters.locale } }">{{ 'Новини' | translate }}</router-link>
                                </li>
                                <li class="">
                                    <router-link exact :to="{ name: 'home', hash: '#contact-us', params: { locale: $store.getters.locale } }">{{ 'Контакти' | translate }}</router-link>
                                </li>
                                <li class="">
                                    <router-link exact :to="{ name: 'home', hash: '#book-an-appointment', params: { locale: $store.getters.locale } }">{{ 'Бронювання онлайн' | translate }}</router-link>
                                </li>
                            </ul>
                        </nav>

                        <div class="mobile-menu-container">
                            <div class="mobile-menu-divider"></div>
                            <nav>
                                <ul class="mobile-menu collapsible-mobile-submenus">
                                    <li class="">
                                        <router-link exact :to="{ name: 'home', params: { locale: $store.getters.locale } }">{{ 'Головна' | translate }}</router-link>
                                    </li>
                                    <li class="">
                                        <router-link exact :to="{ name: 'home', hash: '#about-us', params: { locale: $store.getters.locale } }">{{ 'Про нас' | translate }}</router-link>
                                    </li>
                                    <li class="">
                                        <router-link exact :to="{ name: 'home', hash: '#advantages', params: { locale: $store.getters.locale } }">{{ 'Переваги' | translate }}</router-link>
                                    </li>
                                    <li class="">
                                        <router-link exact :to="{ name: 'home', hash: '#services', params: { locale: $store.getters.locale } }">{{ 'Послуги' | translate }}</router-link>
                                    </li>
                                    <li class="">
                                        <router-link exact :to="{ name: 'home', hash: '#prices', params: { locale: $store.getters.locale } }">{{ 'Ціни' | translate }}</router-link>
                                    </li>
                                    <li class="">
                                        <router-link exact :to="{ name: 'news', params: { locale: $store.getters.locale } }">{{ 'Новини' | translate }}</router-link>
                                    </li>
                                    <li class="">
                                        <router-link exact :to="{ name: 'home', hash: '#contact-us', params: { locale: $store.getters.locale } }">{{ 'Контакти' | translate }}</router-link>
                                    </li>

                                    <li class="">
                                        <router-link exact :to="{ name: 'home', hash: '#book-an-appointment', params: { locale: $store.getters.locale } }">{{ 'Бронювання онлайн' | translate }}</router-link>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </div>
                </div>
            </div>
        </div>
</template>


<script>
    export default {
        mounted() {
        }
    }
</script>


<style>
    img.logo-img {
        height: 50px;
    }
</style>
