<template>
    <div id="services" class="row page-margin-top-section padding-bottom-66">
        <div class="row">
            <h2 class="box-header">{{ 'Пропонуємо такі послуги' | translate }}</h2>
        </div>
        <div class="row page-margin-top-section">
            <div v-for="service of services" class="column column-1-3">
                <ul class="features-list">
                    <li>
                        <h5>{{ service.title($store.getters.locale) }}</h5>
                        <div class="icon" :class="service.icon()"></div>
                        <p>{{ service.description($store.getters.locale) }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Services",
        computed: {
            services() {
                return this.$store.getters.services;
            },
        },
    }
</script>

<style scoped>
    h5, .box-header {
        text-transform: uppercase;
    }
</style>