<template>
    <div class="row full-width gray page-padding-top-section page-margin-top-section padding-bottom-70">
        <div id="advantages" class="row">
            <h2 class="box-header">{{ 'Чому варто обрати нас' | translate }}</h2>
            <p class="description align-center">{{ 'Ми орієнтуємось в першу чергу на комфорт та безпеку, а також орієнтуємось в першу чергу на наших клієнтів.' | translate }}</p>

            <ul v-for="(chunk, index) in chunk(advantages, 2)"
                :class="{
                    'page-margin-top': index === 0,
                    'margin-top-30': index !== 0,
                }"
                class="services-list services-icons row clearfix page-margin-top">
                <li v-for="advantage of chunk">
                    <div class="hexagon small">
                        <span :class="advantage.icon()" class="service-icon"></span>
                    </div>
                    <div class="service-content">
                        <h4 class="box-header">{{ advantage.title($store.getters.locale) }}</h4>
                        <p v-html="advantage.description($store.getters.locale)"></p>
                    </div>
                </li>
            </ul>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Advantages",
        computed: {
            advantages() {
                return this.$store.getters.advantages;
            },
        },
    }
</script>

<style scoped>
    .box-header {
        text-transform: uppercase;
    }
</style>
