import Homepage from '../pages/Homepage.vue'
import News from "../pages/News";
import NewsPost from "../pages/NewsPost";
import Vue from 'vue'
import VueRouter from "vue-router";
import PageNotFound from "../components/PageNotFound";

Vue.use(VueRouter);


const createRouter = () => {
    const routesList = [
        {
            path: '/:locale',
            name: 'home',
            component: Homepage,
        },
        {
            path: '/:locale/news',
            name: 'news',
            component: News,
        },
        {
            path: '/:locale/news/:slug',
            name: 'news-post',
            props: true,
            component: NewsPost,
        },
        {
            path: '*',
            name: 'not-found',
            component: PageNotFound
        }
    ];

    const routes = {
        mode: 'history',
        scrollBehavior (to, from, savedPosition) {
            let position = {};
            if (savedPosition) {
                position = savedPosition;
            } else if (to.hash) {
                position = {
                    selector: to.hash,
                    offset: {
                        y: 110 ,
                    },
                }
            } else {
                position = {
                    x: 0,
                    y: 0,
                };
            }

            return position;
        },
        routes: routesList,
    };

    return new VueRouter(routes);
};

export { createRouter };
