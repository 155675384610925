<template>
    <div id="about-us" class="row page-margin-top-section">
        <div class="column column-1-2">
            <h3 class="box-header">{{ 'Кілька слів про нас' | translate }}</h3>
            <p class="description">
                <vue-markdown :source="aboutUs.description($store.getters.locale)"></vue-markdown>
            </p>

            <vue-markdown :source="aboutUs.content($store.getters.locale)"></vue-markdown>

            <div class="page-margin-top">
                <router-link exact :to="{ name: 'home', hash: '#book-an-appointment', params: { locale: $store.getters.locale } }" class="more">
                    <span>{{ 'Забронювати' | translate }}</span>
                </router-link>
            </div>
        </div>


        <div class="column column-1-2">
            <div v-for="chunk of chunk(aboutUs.images(), 2)" class="row margin-top-30">
                <div v-for="imageUrl of chunk" class="column column-1-2">
                    <a rel="prettyPhoto[gallery]" :href="imageUrl" class="prettyPhoto">
                        <img :src="imageUrl" class="img-frame" style="display: block;">
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import VueMarkdown from "vue-markdown";

    export default {
        name: "AboutUs",
        components: {
            'vue-markdown': VueMarkdown,
        },
        computed: {
            aboutUs() {
                return this.$store.getters.about_us;
            },
        },
        mounted() {
            this.$loadScript('/js/jquery.prettyPhoto.js')
                .then(function () {
                    $("[rel^='prettyPhoto']").prettyPhoto({
                        show_title: false,
                        overlay_gallery: true,
                        social_tools: '',
                        deeplinking: false,
                        markup: '<div class="pp_pic_holder"> \
                            <div class="ppt">&nbsp;</div> \
                            <div class="pp_top"> \
                                <div class="pp_left"></div> \
                                <div class="pp_middle"></div> \
                                <div class="pp_right"></div> \
                            </div> \
                            <div class="pp_content_container"> \
                                <div class="pp_left"> \
                                <div class="pp_right"> \
                                    <div class="pp_content"> \
                                        <div class="pp_loaderIcon"></div> \
                                        <div class="pp_fade"> \
                                            <a href="#" class="pp_expand">Expand</a> \
                                            <div class="pp_hoverContainer"> \
                                                <a class="pp_next" href="#">next</a> \
                                                <a class="pp_previous" href="#">previous</a> \
                                            </div> \
                                            <div id="pp_full_res"></div> \
                                            <div class="pp_details"> \
                                                <div class="pp_nav"> \
                                                    <a href="#" class="pp_arrow_previous">Previous</a> \
                                                    <p class="currentTextHolder">0/0</p> \
                                                    <a href="#" class="pp_arrow_next">Next</a> \
                                                </div> \
                                                <p class="pp_description"></p> \
                                                {pp_social} \
                                                <a class="pp_close" href="#">Close</a> \
                                            </div> \
                                        </div> \
                                    </div> \
                                </div> \
                                </div> \
                            </div> \
                            <div class="pp_bottom"> \
                                <div class="pp_left"></div> \
                                <div class="pp_middle"></div> \
                                <div class="pp_right"></div> \
                            </div> \
                        </div> \
                        <div class="pp_overlay"></div>',
                    });
                });
        }
    }
</script>

<style scoped>
    a.more > span {
        text-transform: uppercase;
    }
</style>

<style>
    div.pp_hoverContainer {
        display: block !important;
    }
    .box-header {
        text-transform: uppercase;
    }

    img.img-frame {
        background-color: #7d7d7d;
        background-image: url("../assets/images/backgrounds/patterns/pattern_7.png");
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
        padding: 3% 0 3% 0;
        margin: 1% 0 1% 0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    div.pp_default .pp_close {
        margin-top: 0 !important;
    }

    div.pp_default .pp_expand {
        top: 20px;
    }
</style>