import BaseModel from "./BaseModel";

export default class Service extends BaseModel {
    id() {
        return this.get('id');
    }

    icon() {
        return this.get('icon');
    }

    title(locale) {
        return this.getTranslation('title', locale);
    }

    description(locale) {
        return this.getTranslation('description', locale);
    }
}
