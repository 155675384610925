export default class BaseModel {
    constructor(data) {
        this.data = data || {};
    }

    get(field) {
        if (!this.data.hasOwnProperty(field)) {
            return '';
        }
        return this.data[field];
    }

    getTranslation(field, locale) {
        if (!this.data.hasOwnProperty(field) || !this.data[field].hasOwnProperty(locale)) {
            return '';
        }
        return this.data[field][locale];
    }
}
