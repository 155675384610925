<template>

    <div class="theme-page padding-bottom-66">

        <div class="row gray full-width page-header vertical-align-table">
            <div class="row full-width padding-top-bottom-50 vertical-align-cell">
                <div class="row">
                    <div class="page-header-left">
                        <h1>{{ 'Новини' | translate }}</h1>
                    </div>
                    <div class="page-header-right">
                        <div class="bread-crumb-container">
                            <ul class="bread-crumb">
                                <li>
                                    <router-link exact :to="{ name: 'home', params: { locale: $store.getters.locale } }">{{ 'Головна' | translate }}</router-link>
                                </li>
                                <li class="separator">
                                    &#47;
                                </li>
                                <li>
                                    {{ 'Новини' | translate }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="clearfix">
            <div class="row margin-top-70">
                <div class="column column-3-4">
                    <ul class="blog clearfix">
                        <post-card
                                v-for="post in posts"
                                v-bind:post="post"
                                v-bind:key="post.id()"
                        ></post-card>
                    </ul>


<!--                    <ul class="pagination page-margin-top">-->
<!--                        <li class="left">-->
<!--                            <a href="index.html@page=blog.html#" title="" class="template-arrow-left-1">&nbsp;</a>-->
<!--                        </li>-->
<!--                        <li class="selected">-->
<!--                            <a href="index.html@page=blog.html#" title="">-->
<!--                                1-->
<!--                            </a>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a href="index.html@page=blog.html#" title="">-->
<!--                                2-->
<!--                            </a>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a href="index.html@page=blog.html#" title="">-->
<!--                                3-->
<!--                            </a>-->
<!--                        </li>-->
<!--                        <li class="right">-->
<!--                            <a href="index.html@page=blog.html#" title="" class="template-arrow-left-1">&nbsp;</a>-->
<!--                        </li>-->
<!--                    </ul>-->
                </div>



                <div class="column column-1-4 re-smart-column">
                    <div class="re-smart-column-wrapper">
                        <book-appointment></book-appointment>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import PostCard from "../components/News/PostCard";
    import BookAppointment from "../components/News/BookAppointment";

    export default {
        name: "News",

        components: {
            'post-card': PostCard,
            'book-appointment': BookAppointment,
        },

        computed: {
            posts() {
                return this.$store.getters.posts;
            }
        },

        metaInfo() {
            return {
                title: this.$store.getters.news_seo.title[this.$store.getters.locale],
                meta: [
                    {
                        name: 'description',
                        content: this.$store.getters.news_seo.description[this.$store.getters.locale],
                    },
                    {
                        name: 'keywords',
                        content: this.$store.getters.news_seo.keywords[this.$store.getters.locale],
                    },
                ],
            }
        },
    }
</script>

<style scoped>
    @media screen and (max-width: 1189px) {
        div.page-header-right {
            display: none;
        }
    }

    h1,
    .page-header-right {
        text-transform: uppercase;
    }
    .page-header-right {
        width: 850px;
    }
    .page-header-left {
        width: 300px;
    }
</style>
