<template>
    <div id="app">
        <div class="site-container">
            <topbar></topbar>
            <navbar></navbar>
            <div id="pageview">
                <router-view></router-view>
            </div>
        </div>

        <app-footer></app-footer>

        <vue-snotify></vue-snotify>
        <scroll-to-top></scroll-to-top>
    </div>

</template>

<script>
    import Navbar from './Navbar'
    import Topbar from './Topbar'
    import Footer from './Footer'
    import ScrollToTop from "./ScrollToTop";


    import '../assets/rs-plugin/css/settings.css'
    import '../assets/style/reset.css'
    import '../assets/style/superfish.css'
    import '../assets/style/prettyPhoto.css'
    import '../assets/style/jquery.qtip.css'
    import '../assets/style/style.css'
    import '../assets/style/animations.css'
    import '../assets/style/responsive.css'
    import '../assets/style/odometer-theme-default.css'
    import '../assets/fonts/streamline-small/style.css'
    import '../assets/fonts/template/styles.css'
    import '../assets/fonts/social/styles.css'


    export default {
        name: 'App',
        components: {
            'topbar': Topbar,
            'navbar': Navbar,
            'app-footer': Footer,
            'scroll-to-top': ScrollToTop,
        },
        mounted() {
            this.$loadScript('/js/mainjs.js');

            if (!this.$store.getters.ready) {
                this.fetchCommonInfo()
            }
        },

        serverPrefetch () {
            return this.fetchCommonInfo()
        },

        methods: {
            fetchCommonInfo() {
                return this.$store.dispatch('fetchCommonInfo')
            }
        }
    }
</script>

<style lang="scss">
    @import "~vue-snotify/styles/material";
</style>

<style>
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,400,600,700,800&amp;subset=latin,latin-ext');
</style>

