<template>
    <div id="book-an-appointment" class="clearfix padding-bottom-50">
        <div class="row page-margin-top">
            <div class="column column-1-1">
                <div class="row">
                    <h2 class="box-header">{{ 'Забронювати місце' | translate }}</h2>
                    <p class="description align-center">
                        {{ 'Будь ласка, додайте інформацію про себе та про Ваш візит до нас.' | translate }}
                        <br>
                        {{ 'Заповнюючи цю форму, ми автоматично бронюємо для Вас місце на автостоянці.' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div class="row page-margin-top">
            <form novalidate
                  class="contact-form cost-calculator-container"
                  id="contact-form">

                <div class="row">
                    <fieldset class="column column-1-2">
                        <label :class="{ 'hasError': $v.appointment.name.$error }">{{ "Ім'я" | translate }}</label>
                        <input class="text-input" v-model="appointment.name" :placeholder="'Тарас Шевченко' | translate">
                    </fieldset>
                    <fieldset class="column column-1-2">
                        <label :class="{ 'hasError': $v.appointment.phone.$error }">{{ 'Номер телефону' | translate }}</label>
                        <input class="text-input" v-model="appointment.phone" :placeholder="'+380981234567' | translate">
                    </fieldset>
                </div>

                <div class="row page-margin-top">
                    <fieldset class="column column-1-2">
                        <label :class="{ 'hasError': $v.appointment.arriving_time.$error }">{{ 'Орієнтовний час візиту' | translate }}</label>
                        <select class="cost-dropdown" v-model="appointment.arriving_time">
                            <option value="" selected="selected">{{ 'Оберіть час, в який плануєте приїхати до нас' | translate }}</option>
                            <option value="00:00,03:00">{{ '00:00 - 03:00' | translate }}</option>
                            <option value="03:00,06:00">{{ '03:00 - 06:00' | translate }}</option>
                            <option value="06:00,09:00">{{ '06:00 - 09:00' | translate }}</option>
                            <option value="09:00,12:00">{{ '09:00 - 12:00' | translate }}</option>
                            <option value="12:00,15:00">{{ '12:00 - 15:00' | translate }}</option>
                            <option value="15:00,18:00">{{ '15:00 - 18:00' | translate }}</option>
                            <option value="18:00,21:00">{{ '18:00 - 21:00' | translate }}</option>
                            <option value="21:00,00:00">{{ '21:00 - 00:00' | translate }}</option>
                        </select>
                    </fieldset>
                    <fieldset class="column column-1-2">
                        <label :class="{ 'hasError': $v.appointment.license_plate.$error }">{{ 'Номер вашого автомобіля' | translate }}</label>
                        <input class="text-input" v-model="appointment.license_plate" :placeholder="'AA 1234 BH' | translate" v-uppercase>
                    </fieldset>
                </div>

                <div class="row page-margin-top">
                    <fieldset class="column column-1-1">
                        <div class="cost-calculator-box clearfix">
                            <label :class="{ 'hasError': $v.appointment.arriving_date_start.$error || $v.appointment.arriving_date_end.$error }" class="cost-calculator-box-datepicker-label">
                                {{ 'Оберіть дати перебування' | translate }}
                            </label>
                            <input type="hidden" v-model="appointment.arriving_date_start" id="v-model-appointment-arriving_date_start">
                            <input type="hidden" v-model="appointment.arriving_date_end" id="v-model-appointment-arriving_date_end">
                            <div class="cost-slider-datepicker"></div>
                        </div>
                        <a v-on:click.prevent="submit" class="more margin-top-20 display-block submit-contact-form"><span>{{ 'Забронювати' | translate }}</span></a>
                    </fieldset>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
    import { required } from "vuelidate/lib/validators";
    import api from '../helpers/Api';

    export default {
        name: "AppointmentForm",

        data () {
            const defaultAppointment = {
                name: '',
                phone: '',
                license_plate: '',
                arriving_time: '',
                arriving_date_start: '',
                arriving_date_end: '',
            };

            return {
                defaultAppointment: Object.assign({}, defaultAppointment),
                appointment: Object.assign({}, defaultAppointment),
            }
        },

        validations: {
            appointment: {
                name: { required },
                phone: { required },
                license_plate: { required },
                arriving_time: { required },
                arriving_date_start: { required },
                arriving_date_end: { required },
            }
        },

        methods: {
            submit() {
                this.$v.appointment.$touch();
                // if its still pending or an error is returned do not submit
                if (this.$v.appointment.$pending || this.$v.appointment.$error) {
                    return;
                }


                api.appointment(this.appointment)
                    .then(res => res.ok ? res : Promise.reject(res))
                    .then(() => {
                        this.$snotify.success(this.$store.getters.translations('Бронювання підтверджено. При необхідності ми Вам зателефонуємо.'), {
                            timeout: 8000,
                        });
                        this.resetForm()
                    }).catch((err) => {
                        this.$snotify.error(this.$store.getters.translations('Онлайн бронювання тимчасово не працює. Скористайтеся контактним номером для бронювання.'), {
                            timeout: 20000,
                        });
                    });
            },

            resetForm() {
                this.appointment = this.defaultAppointment;
                this.$nextTick(() => {
                    $(".cost-slider-datepicker").datepicker('setDate', 0);
                    $(".cost-dropdown").selectmenu("refresh");

                    this.$v.$reset();
                })
            },

            formatDate(d) {
                var month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');
            }
        },

        watch: {
            '$store.state.locale' () {
                $(".cost-slider-datepicker").datepicker('option', $.datepicker.regional[this.$store.getters.locale]);
            }
        },

        mounted() {
            require('../assets/js/jquery-ui-1.11.4.custom.min.js')
            require('../assets/js/jquery.ui.touch-punch.min.js')
            require('../assets/js/jquery.datepicker.extension.range.min.js')

            // this.$nextTick(() => {
                $.datepicker.regional['uk'] = {
                    closeText: "Закрити",
                    prevText: "",
                    nextText: "",
                    currentText: "Сьогодні",
                    monthNames: [ "Січень","Лютий","Березень","Квітень","Травень","Червень", "Липень","Серпень","Вересень","Жовтень","Листопад","Грудень" ],
                    monthNamesShort: [ "Січ","Лют","Бер","Кві","Тра","Чер", "Лип","Сер","Вер","Жов","Лис","Гру" ],
                    dayNames: [ "неділя","понеділок","вівторок","середа","четвер","п’ятниця","субота" ],
                    dayNamesShort: [ "нед","пнд","вів","срд","чтв","птн","сбт" ],
                    dayNamesMin: [ "Нд","Пн","Вт","Ср","Чт","Пт","Сб" ],
                    weekHeader: "Тиж",
                    dateFormat: "dd.mm.yy",
                    firstDay: 1,
                    isRTL: false,
                    showMonthAfterYear: false,
                    yearSuffix: ""
                };
                $.datepicker.regional['ru'] = {
                    closeText: "Закрыть",
                    prevText: "",
                    nextText: "",
                    currentText: "Сегодня",
                    monthNames: [ "Январь","Февраль","Март","Апрель","Май","Июнь", "Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь" ],
                    monthNamesShort: [ "Янв","Фев","Мар","Апр","Май","Июн", "Июл","Авг","Сен","Окт","Ноя","Дек" ],
                    dayNames: [ "воскресенье","понедельник","вторник","среда","четверг","пятница","суббота" ],
                    dayNamesShort: [ "вск","пнд","втр","срд","чтв","птн","сбт" ],
                    dayNamesMin: [ "Вс","Пн","Вт","Ср","Чт","Пт","Сб" ],
                    weekHeader: "Нед",
                    dateFormat: "dd.mm.yy",
                    firstDay: 1,
                    isRTL: false,
                    showMonthAfterYear: false,
                    yearSuffix: ""
                };

                const vm = this;
                $(".cost-slider-datepicker").datepicker({
                    inline: true,
                    range: 'period',
                    dateFormat: "DD, d MM yy",
                    nextText: "",
                    prevText: "",
                    firstDay: 1,
                    minDate: 0,
                    numberOfMonths: 2,
                    onSelect: function(dateText, inst, extensionRange) {
                        vm.appointment.arriving_date_start = vm.formatDate(extensionRange.startDate);
                        vm.appointment.arriving_date_end = vm.formatDate(extensionRange.endDate);
                    },
                }).datepicker('setDate', 0);
                $(".cost-slider-datepicker").datepicker("option", $.datepicker.regional[this.$store.getters.locale]);


                $(".cost-dropdown").selectmenu({
                    icons: {
                        button: "template-arrow-dropdown"
                    },
                    change: function(event, ui){
                        vm.appointment.arriving_time = ui.item.value;
                    },
                });
            // })
        }
    }
</script>

<style scoped>
    a.submit-contact-form,
    label {
        text-transform: uppercase;
    }
</style>

<style>
    @media screen and (max-width: 1189px) {
        span.ui-selectmenu-button {
            width: 360px !important;;
        }
    }
    @media screen and (max-width: 767px) {
        span.ui-selectmenu-button {
            width: 100% !important;
        }
        .ui-datepicker-inline.ui-datepicker div.ui-datepicker-group {
            width: 100%;
        }
    }
    @media screen and (max-width: 479px) {
        span.ui-selectmenu-button  .ui-selectmenu-text {
            width: 240px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }


    div.snotify-success div.snotifyToast__body {
        color: #fff;
    }

    div.cost-slider-datepicker {
        margin-top: 20px;
    }

    label.cost-calculator-box-datepicker-label {
        margin-bottom: 1px;
    }
    label.hasError {
        border-bottom: 1px red solid;
    }

    table.ui-datepicker-calendar tbody tr td:hover {
        -webkit-box-shadow: inset 0px 0px 10px -4px #1e69b8;
        -moz-box-shadow: inset 0px 0px 10px -4px #1e69b8;
        box-shadow: inset 0px 0px 10px -4px #1e69b8;
    }
    table.ui-datepicker-calendar tbody tr td.ui-datepicker-unselectable.ui-state-disabled:hover {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    table.ui-datepicker-calendar tbody tr td,
    table.ui-datepicker-calendar tbody tr td .ui-state-default {
        cursor: pointer;
        text-decoration: none;
    }
    table.ui-datepicker-calendar tbody tr td.ui-datepicker-unselectable.ui-state-disabled,
    table.ui-datepicker-calendar tbody tr td.ui-datepicker-unselectable.ui-state-disabled .ui-state-default {
        cursor: default;
    }
    table.ui-datepicker-calendar td.ui-datepicker-unselectable.ui-state-disabled.ui-datepicker-other-month {
        background-color: #fff;
    }
    table.ui-datepicker-calendar td.ui-state-disabled {
        background-color: whitesmoke;
    }

    .ui-datepicker-inline.ui-datepicker {
        width: auto !important;
    }
    .ui-datepicker-inline.ui-datepicker .ui-datepicker-group {
        width: 50%;
        display: inline-block;
    }

    .ui-datepicker td.selected a {
        color: #fff;
    }
    .ui-datepicker td.selected {
        background: #1E69B8;
    }
</style>
