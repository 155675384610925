<template>
    <div class="row full-width padding-top-70 padding-bottom-66 parallax parallax-1 slotholder">
        <div class="row testimonials-container">
            <a href="#" class="slider-control left template-arrow-left-1"></a>
            <ul class="testimonials-list autoplay-true">
                <li v-for="(testimonial, index) of testimonials"
                    :style="{
                    'display: none': index !== 0,
                }">
                    <div>
                        <img class="star" src="../assets/star2.svg">
                        <img class="star" src="../assets/star2.svg">
                        <img class="star" src="../assets/star2.svg">
                        <img class="star" src="../assets/star2.svg">
                        <img class="star" src="../assets/star2.svg">
                    </div>
                    <p>"{{ testimonial.content() }}"</p>
                    <h6>{{ testimonial.name() }}</h6>
                    <div v-if="testimonial.source($store.getters.locale)" class="author-details">{{ testimonial.source($store.getters.locale) }}</div>
                </li>
            </ul>
            <a href="#" class="slider-control right template-arrow-left-1"></a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Testimonials",

        data () {
            return {
                scriptsDownloaded: false,
            };
        },

        computed: {
            testimonials() {
                return this.$store.getters.testimonials;
            },
        },

        mounted() {
            Promise.all([
                this.$loadScript("/js/jquery.carouFredSel-6.2.1-packed.js"),
                this.$loadScript("/js/jquery.touchSwipe.min.js"),
                this.$loadScript("/js/jquery.easing.1.3.min.js"),
            ]).then(()=>{
                this.scriptsDownloaded = true;
            });
        },

        methods: {
            initTestimonials() {
                this.$nextTick(function () {
                    $(".testimonials-list").each(function () {
                        let self = $(this);
                        let elementClasses = $(this).attr('class').split(' ');
                        let autoplay = 0;
                        let pause_on_hover = 0;
                        let scroll = 1;
                        let effect = "scroll";
                        let easing = "easeInOutQuint";
                        let duration = 750;
                        for (let i = 0; i < elementClasses.length; i++) {
                            if (elementClasses[i].indexOf('autoplay-') != -1)
                                autoplay = elementClasses[i].replace('autoplay-', '');
                            if (elementClasses[i].indexOf('pause_on_hover-') != -1)
                                pause_on_hover = elementClasses[i].replace('pause_on_hover-', '');
                            if (elementClasses[i].indexOf('scroll-') != -1)
                                scroll = elementClasses[i].replace('scroll-', '');
                            if (elementClasses[i].indexOf('effect-') != -1)
                                effect = elementClasses[i].replace('effect-', '');
                            if (elementClasses[i].indexOf('easing-') != -1)
                                easing = elementClasses[i].replace('easing-', '');
                            if (elementClasses[i].indexOf('duration-') != -1)
                                duration = elementClasses[i].replace('duration-', '');
                        }
                        self.carouFredSel({
                                onCreate: function () {
                                    self.find('li').show();
                                },
                                /*responsive: true,*/
                                width: "auto",
                                items: {
                                    visible: 1
                                },
                                scroll: {
                                    items: 1,
                                    fx: effect,
                                    easing: easing,
                                    duration: parseInt(duration, 10),
                                    pauseOnHover: (parseInt(pause_on_hover) ? true : false)
                                },
                                auto: {
                                    play: (parseInt(autoplay) ? true : false),
                                    fx: effect,
                                    easing: easing,
                                    duration: parseInt(duration, 10),
                                    pauseOnHover: (parseInt(pause_on_hover) ? true : false)
                                },
                                pagination: {
                                    container: $(self).prev(".cs-carousel-pagination")
                                },
                                'prev': {button: self.prev()},
                                'next': {button: self.next()}
                            },
                            {
                                transition: true,
                                wrapper: {
                                    classname: "caroufredsel_wrapper caroufredsel_wrapper_testimonials"
                                }
                            });
                        let base = "x";
                        let scrollOptions = {
                            scroll: {
                                easing: "easeInOutQuint",
                                duration: 750
                            }
                        };
                        self.swipe({
                            fallbackToMouseEvents: true,
                            allowPageScroll: "vertical",
                            excludedElements: "button, input, select, textarea, .noSwipe",
                            swipeStatus: function (event, phase, direction, distance, fingerCount, fingerData) {
                                if (!self.is(":animated")) {
                                    self.trigger("isScrolling", function (isScrolling) {
                                        if (!isScrolling) {
                                            if (phase == "move" && (direction == "left" || direction == "right")) {
                                                if (base == "x") {
                                                    self.trigger("configuration", scrollOptions);
                                                    self.trigger("pause");
                                                }
                                                if (direction == "left") {
                                                    if (base == "x")
                                                        base = 0;
                                                    self.css("left", parseInt(base, 10) - distance + "px");
                                                } else if (direction == "right") {
                                                    if (base == "x" || base == 0) {
                                                        self.children().last().prependTo(self);
                                                        base = -self.children().first().width() - parseInt(self.children().first().css("margin-right"), 10);
                                                    }
                                                    self.css("left", base + distance + "px");
                                                }

                                            } else if (phase == "cancel") {
                                                if (distance != 0) {
                                                    self.trigger("play");
                                                    self.animate({
                                                        "left": base + "px"
                                                    }, 750, "easeInOutQuint", function () {
                                                        if (base == -self.children().first().width() - parseInt(self.children().first().css("margin-right"), 10)) {
                                                            self.children().first().appendTo(self);
                                                            self.css("left", "0px");
                                                            base = "x";
                                                        }
                                                        self.trigger("configuration", {
                                                            scroll: {
                                                                easing: "easeInOutQuint",
                                                                duration: 750
                                                            }
                                                        });
                                                    });
                                                }
                                            } else if (phase == "end") {
                                                self.trigger("play");
                                                if (direction == "right") {
                                                    self.trigger('ql_set_page_nr', 1);
                                                    self.animate({
                                                        "left": 0 + "px"
                                                    }, 750, "easeInOutQuint", function () {
                                                        self.trigger("configuration", {
                                                            scroll: {
                                                                easing: "easeInOutQuint",
                                                                duration: 750
                                                            }
                                                        });
                                                        base = "x";
                                                    });
                                                } else if (direction == "left") {
                                                    if (base == -self.children().first().width() - parseInt(self.children().first().css("margin-right"), 10)) {
                                                        self.children().first().appendTo(self);
                                                        self.css("left", (parseInt(self.css("left"), 10) - base) + "px");
                                                    }
                                                    self.trigger("nextPage");
                                                    base = "x";
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    });
                });
            },
        },

        watch: {
            testimonials () {
                if (!this.scriptsDownloaded) {
                    return;
                }
                this.initTestimonials();
            },

            scriptsDownloaded () {
                if (!this.testimonials.length) {
                    return;
                }
                this.initTestimonials();
            },
        }
    }
</script>

<style scoped>
    .star {
        height: 46px;
        margin-right: 6px;
    }
    @media screen and (max-width: 767px) {
        .star {
            height: 36px;
        }
    }
    @media screen and (max-width: 479px) {
        .star {
            height: 26px;
        }
    }

    .slotholder {
        position: relative;
        background: url('../assets/testimonials-background.jpg');
        background-size: cover;
    }
    .slotholder:before
    {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(30,30,30,0.5);
    }
</style>