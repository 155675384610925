<template>
<div>
    <div class="row align-center padding-top-bottom-30">
        <span class="copyright">Copyright © {{ year }} All Rights Reserved.</span>
    </div>
</div>
</template>

<script>
    export default {
        data () {
            return {
                year: new Date().getFullYear(),
            };
        },
    }
</script>
