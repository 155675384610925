import BaseModel from "./BaseModel";

export default class Testimonial extends BaseModel {
    content() {
        return this.get('content');
    }

    name() {
        return this.get('name');
    }

    source(locale) {
        return this.getTranslation('source', locale);
    }
}
