import BaseModel from "./BaseModel";

export default class AboutUs extends BaseModel {

    description(locale) {
        return this.getTranslation('about_us_description', locale);
    }

    content(locale) {
        return this.getTranslation('about_us_content', locale);
    }

    images() {
        return this.get('images') || [];
    }
}
