<template>
    <div class="call-to-action">
        <div class="hexagon small">
            <div class="sl-small-share-time"></div>
        </div>
        <h4 class="margin-top-58">{{ 'Миттєве бронювання онлайн' | translate }}</h4>
        <p class="description">{{ 'Кілька хвилин і готово' | translate }}</p>

        <router-link exact :to="{ name: 'home', hash: '#book-an-appointment', params: { locale: $store.getters.locale } }" class="more">
            <span class="uppercase">{{ 'Забронювати' | translate }}</span>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "BookAppointment"
    }
</script>

<style scoped>
    h4,
    a.more > span {
        text-transform: uppercase;
    }
</style>
