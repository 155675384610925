import BaseModel from "./BaseModel";

export default class Slide extends BaseModel {
    icon() {
        return this.get('icon');
    }

    title(locale) {
        return this.getTranslation('title', locale);
    }

    description(locale) {
        return this.getTranslation('description', locale);
    }

    imageUrl() {
        return this.get('image_url');
    }

    showBookAppointmentButton() {
        return this.get('show_book_appointment_button');
    }
}
