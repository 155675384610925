require('./bootstrap');

import Vue from 'vue';
import Vuex from 'vuex';
import LoadScript from './vue-plugin-load-script';
import { createRouter } from './router';
import { createStore } from './store';
import App from './components/App.vue';
import Translator from "./filters/Translator";
import Vuelidate from "vuelidate";
import VueMarkdown from 'vue-markdown';
import VueMeta from 'vue-meta';
import Snotify from 'vue-snotify';

Vue.config.productionTip = false;

Vue.use(LoadScript);
Vue.use(Vuex);
Vue.use(VueMarkdown);
Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(Snotify);



const createApp = (context) => {
    const router = createRouter();
    const store = createStore();

    router.beforeEach((to, from, next) => {
        let localeUrlSegment = to.path.split('/');

        localeUrlSegment.shift()
        let locale = localeUrlSegment[0] || '';
        if (!locale || !['ru', 'uk'].includes(locale)) {
            next({path: `/${store.getters.locale}${to.path}`});
            return;
        }

        store.commit('locale', locale);
        to.params.locale = locale;

        next()
    });


    Vue.filter('translate', function (value) {
        return store.getters.translations(value);
    });
    Vue.filter('uppercase', function (value) {
        return value.toUpperCase();
    });
    Vue.directive("uppercase", {
        update: function (el) {
            el.value = el.value.toUpperCase();
            el.dispatchEvent(new Event('input'));
        }
    });

    Vue.mixin({
        methods: {
            chunk: function(myArray, chunk_size) {
                let arrayLength = myArray.length;
                let tempArray = [];

                for (let index = 0; index < arrayLength; index += chunk_size) {
                    let  myChunk = myArray.slice(index, index+chunk_size);
                    tempArray.push(myChunk);
                }

                return tempArray;
            },
            sanitizePhone: function (phone) {
                return phone.replace(/[^0-9]/g, '');
            },
        }
    });


    const app = new Vue({
        render: h => h(App),
        router: router,
        store: store,
    });

    return { app, router, store };
};

export { createApp };
