import Vue from 'vue';
import Vuex from 'vuex';
import api from "../helpers/Api";
import Translator from "../filters/Translator";
import AboutUs from "../models/AboutUs";
import Service from "../models/Service";
import Advantage from "../models/Advantage";
import Post from "../models/Post";
import Slide from "../models/Slide";
import Testimonial from "../models/Testimonial";

Vue.use(Vuex);

const createStore = () => {
    return new Vuex.Store({
        strict: process.env.NODE_ENV !== 'production',
        // IMPORTANT: state must be a function so the module can be
        // instantiated multiple times
        state: () => ({
            ready: false,
            defaultLocale: 'ru',
            allowedLocales: ['uk', 'ru'],
            locale: '',
            phones: [],
            cab_phones: [],
            email: '',
            facebook_url: '',
            address: '',
            homepage_seo: {
                title: { uk: '', ru: '' },
                description: { uk: '', ru: '' },
                keywords: { uk: '', ru: '' },
            },
            news_seo: {
                title: { uk: '', ru: '' },
                description: { uk: '', ru: '' },
                keywords: { uk: '', ru: '' },
            },
            translations: {},
            slides: [],
            posts: [],
            services: [],
            advantages: [],
            about_us: {
                about_us_description: { uk: '', ru: '' },
                about_us_content: { uk: '', ru: '' },
                images: [],
            },
            testimonials: [],
            price: { uk: '', ru: '' },
        }),
        getters: {
            ready: state => {
                return state.ready;
            },
            locale: state => {
                return state.locale || state.defaultLocale;
            },
            defaultLocale: state => {
                return state.defaultLocale;
            },
            locales: state => {
                return state.allowedLocales;
            },
            phones: state => {
                return state.phones || [];
            },
            cab_phones: state => {
                return state.cab_phones || [];
            },
            email: state => {
                return state.email;
            },
            facebook_url: state => {
                return state.facebook_url;
            },
            address: state => {
                return state.address;
            },
            homepage_seo: state => {
                return state.homepage_seo;
            },
            news_seo: state => {
                return state.news_seo;
            },
            translations: state => phrase => {
                if (!state.ready) {
                    return phrase;
                }


                if (state.translations.hasOwnProperty(phrase) && state.translations[phrase].hasOwnProperty(state.locale)) {
                    return state.translations[phrase][state.locale];
                }

                if (!state.translations.hasOwnProperty(phrase)) {
                    state.translations[phrase] = {};
                }
                state.translations[phrase][state.locale] = phrase;

                api.notifyMissedTranslation(state.locale, phrase);

                return phrase;
            },
            slides: state => {
                const slides = [];
                for (let slide of state.slides) {
                    slides.push(new Slide(slide))
                }

                return slides;
            },
            posts: state => {
                const posts = [];
                for (let post of state.posts) {
                    posts.push(new Post(post))
                }

                return posts;
            },
            post: state => slug => {
                for (let post of state.posts) {
                    post = new Post(post);
                    if (post.slug() === slug) {
                        return post;
                    }
                }
                return new Post();
            },
            about_us: state => {
                return new AboutUs(state.about_us);
            },
            services: state => {
                const services = [];
                for (let service of state.services) {
                    services.push(new Service(service));
                }

                return services;
            },
            advantages: state => {
                const advantages = [];
                for (let advantage of state.advantages) {
                    advantages.push(new Advantage(advantage));
                }

                return advantages;
            },
            testimonials: state => {
                const testimonials = [];
                for (let testimonial of state.testimonials) {
                    testimonials.push(new Testimonial(testimonial));
                }

                return testimonials;
            },
            price: state => locale => {
                return state.price[locale] || '';
            }
        },
        mutations: {
            ready (state) {
                state.ready = true;
            },
            locale (state, locale) {
                if (!locale || !state.allowedLocales.includes(locale)) {
                    locale = state.defaultLocale;
                }
                state.locale = locale;
            },
            phones (state, phones) {
                state.phones = phones;
            },
            cab_phones (state, phones) {
                state.cab_phones = phones;
            },
            email (state, email) {
                state.email = email;
            },
            facebook_url (state, facebook_url) {
                state.facebook_url = facebook_url;
            },
            address (state, address) {
                state.address = address;
            },
            homepage_seo (state, homepage_seo) {
                state.homepage_seo = homepage_seo;
            },
            news_seo (state, news_seo) {
                state.news_seo = news_seo;
            },
            translations (state, translations) {
                state.translations = translations;
            },
            slides (state, slides) {
                state.slides = slides;
            },
            posts (state, posts) {
                state.posts = posts;
            },
            services (state, services) {
                state.services = services;
            },
            advantages (state, advantages) {
                state.advantages = advantages;
            },
            about_us (state, about_us) {
                state.about_us = about_us;
            },
            testimonials (state, testimonials) {
                state.testimonials = testimonials;
            },
            price (state, price) {
                state.price = price;
            },
        },
        actions: {
            fetchCommonInfo ({ commit }) {
                // return the Promise via `store.dispatch()` so that we know
                // when the data has been fetched
                return api.common().then(function (data) {
                    commit('translations', data.translations);
                    commit('phones', data.settings.phones);
                    commit('cab_phones', data.settings.cab_phones);
                    commit('email', data.settings.email);
                    commit('facebook_url', data.settings.facebook_url);
                    commit('address', data.settings.address);
                    commit('homepage_seo', data.homepage_seo);
                    commit('news_seo', data.news_seo);
                    commit('about_us', data.about_us);
                    commit('slides', data.slides);
                    commit('posts', data.posts);
                    commit('services', data.services);
                    commit('advantages', data.advantages);
                    commit('testimonials', data.testimonials);
                    commit('price', data.settings.price);

                    commit('ready');
                });
            }
        },
    });
};

export { createStore };